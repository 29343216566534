import React, { useState } from 'react';
import { Button, Box, styled, ButtonProps, alpha } from '@mui/material';
import Collapse from "@mui/material/Collapse";
import MarkdownText from './MarkdownText';

const MoreButton = styled(Button)<ButtonProps>(({ theme }) => ({
    border: 'none',
}));

const ExpandableText = ({
  text = '',
  previewHeight = 90,
  allowExpand = true,
  color,
}: {
  text?: string,
  previewHeight?: number,
  allowExpand?: boolean,
  color?: string,
}) => {
  const [expanded, setExpanded] = useState(!allowExpand);

  const handleToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <Box>
        <Collapse
          in={expanded}
          collapsedSize={`${previewHeight}px`}
          timeout={600}
          sx={{
            color: (theme) => color || alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary)
          }}
        >
          <Box
            sx={!expanded ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              textAlign: 'left',
            } : {} }
          >
            <MarkdownText text={text} />
          </Box>
      </Collapse>
      {allowExpand ? <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <MoreButton
          variant='text'
          onClick={handleToggle}
          sx={{
            color: (theme) => alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary)
          }}
        >
          {expanded ? 'Less' : 'More'}
        </MoreButton>
      </Box> : <></>}
    </Box>
  );
};

export default ExpandableText;
