import { Box } from '@mui/material';
import styles from "../App.module.css";
import PlayerScoutingReport from '../components/reports/ScoutingReport';
import { useEffect, useState } from 'react';
import { api } from '../api/twelve';
import { useParams } from 'react-router-dom';
import {
  ReportResponse,
} from '../models';
import LoadingScreen from '../components/reports/common/LoadingScreen';

export default function PublicReportPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [report, setReport] = useState<ReportResponse>();
  const { publicId } = useParams();

  useEffect(() => {
    if (!publicId ) {
      return;
    }
    const getReport = async () => {
      setLoading(true);
      try {
        const report = await api.getReportByPublicId(publicId);
        setReport(report);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [publicId])

  const renderReport = () => {
    switch (report?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...report.content} />;
      default:
        return <></>;
    }
  };

  return <Box className={styles.publicReport}>
     {renderReport()}
     {loading && <LoadingScreen />}
  </Box>
}