import React, {ComponentType, ReactNode, useEffect } from "react";
import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
  useLocation,
} from "react-router-dom";
import styles from "./App.module.css";
import Sidebar from "./components/elements/Sidebar";
import { Box } from "@mui/material";
import { createCustomTheme, PaletteModeContext } from "./themes/theme";
import { ConversationList } from "./components/conversation/ConversationList";
import Conversation from "./pages/Conversation";
import { Auth0Provider, Auth0ProviderOptions, AppState, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Welcome from "./pages/Welcome";
import TopBar from "./components/elements/TopBar";
import { Provider } from 'react-redux';
import store from './store';
import AppModalMenu from "./components/elements/AppModalMenu";
import ExampleConversations from "./components/conversation/ExampleConversations";
import PageLoader from "./components/elements/PageLoader";
import DemoPage from "./pages/DemoPage";
import ReportPage from "./pages/Report";
import ReportsPage from "./pages/Reports";
import { config } from "./config";
import PublicReportPage from "./pages/PublicReport";
import { ThemeProvider } from '@mui/material/styles';

function MainApp() {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <PageLoader variant="full" />;
  } else if (!isAuthenticated) {
    return <Welcome />
  } else {
    return (<>
      <TopBar />
      <AppModalMenu />
      <Box className={styles.main}>
        <Box>
          <Sidebar>
            <ConversationList key="conversations" />
          </Sidebar>
        </Box>
        <Box
          className={styles.page}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {location.pathname === '/'? (
            <ExampleConversations />
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
    </>);
  }
}

function PublicApp() {
  return (<>
    <Box className={styles.main}>
      <Box className={styles.page}>
        <Outlet />
      </Box>
    </Box>
  </>);
}

interface ProtectedRouteProps {
  component: ComponentType<any>;
  [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
}

interface Auth0ProviderWithRedirectCallbackProps extends Auth0ProviderOptions {
  children: ReactNode;
}

const Auth0ProviderWithRedirectCallback: React.FC<Auth0ProviderWithRedirectCallbackProps> = ({ children, ...props }) => {
  const navigate = useNavigate();
  
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0ProviderWithRedirectCallback onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0ProviderWithRedirectCallback>
  );
};

function MainRouter() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* private routes */}
        <Route path="/" element={<MainApp />}>
          <Route path="/conversation/:conversationId" element={<ProtectedRoute component={Conversation} />} />
          <Route path="/reports" element={<ProtectedRoute component={ReportsPage} />} />
          <Route path="/report/:reportId" element={<ProtectedRoute component={ReportPage} />} />
          <Route path="demo" element={<ProtectedRoute component={DemoPage} />} />
        </Route>
        {/* public routes */}
        <Route path="/" element={<PublicApp />}>
          <Route path="/reports/:publicId" element={<PublicReportPage />} />
        </Route>
      </Route>
    )
  );
  return (
    <RouterProvider router={router} />
  );
}


function App() {
  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [mode, setMode] = React.useState<'light' | 'dark'>('light');

  const paletteMode = React.useMemo(
    () => ({
      togglePaletteMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const customTheme = React.useMemo(
    () => createCustomTheme(mode),
    [mode],
  );

  return (
    <Provider store={store}>
      <PaletteModeContext.Provider value={paletteMode}>
        <ThemeProvider theme={customTheme}>
          <Auth0Provider
              domain={config.auth0.domain}
              clientId={config.auth0.clientId}
              authorizationParams={{ redirect_uri: window.location.origin, audience: config.auth0.audience }}>
            <MainRouter />
          </Auth0Provider>
        </ThemeProvider>
      </PaletteModeContext.Provider>
    </Provider>
  );
}

export default App;
