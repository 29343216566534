import { Box } from '@mui/material';
import PlayerScoutingReport from '../components/reports/ScoutingReport';
import { useEffect, useState } from 'react';
import { api } from '../api/twelve';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import {
  ReportContent,
} from '../models';
import LoadingScreen from '../components/reports/common/LoadingScreen';

export default function ReportPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent>();
  const { reportId } = useParams();

  useEffect(() => {
    if (!reportId) {
      return;
    }

    const getReport = async () => {
      setLoading(true);
      try {
        const data = await api.getReportContentById(await getAccessTokenSilently(), Number(reportId));
        setReportContent({
          report_type: data?.report_type,
          pages: data.content?.pages,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [getAccessTokenSilently, reportId]);

  const renderReport = () => {
    switch (reportContent?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...reportContent} />;
      default:
        return <></>;
    }
  };

  return <Box
    sx={{
      backgroundColor: (theme) => theme.palette.customColors.report.background,
    }}
  >
    {loading ? <LoadingScreen /> : renderReport()}
  </Box>
}