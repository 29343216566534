import React from 'react';
import ReportLogo from './ReportLogo';
import {
    ReportContainer,
    LastPage,
    Page,
    PageHeader,
    PageContent,
} from './ReportLayout';
import { ReportContent, ReportPage } from '../../models';
import TitlePage from './pages/TitlePage';
import StandardPage from './pages/StandardPage';
import GlossaryPages from './pages/GlossaryPages';
import PlayerSummaryPage from './pages/PlayerSummaryPage';
import { TableOfContent } from './common/TableOfContent';
import { useMediaQuery, useTheme } from '@mui/material';

export default function PlayerScoutingReport({ pages = [] }: ReportContent) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const renderPage = (page: ReportPage, pageNumber: number) => {
        switch (page.type) {
            case 'title_page':
                return <TitlePage page={page} />
            case 'basic_page':
                return <PlayerSummaryPage page={page} pageNumber={pageNumber} />
            case 'standard_page':
                return <StandardPage page={page} pageNumber={pageNumber} />
            case 'glossary_pages':
                return <GlossaryPages items={page.items} />
            default:
                return <></>;
        }
    }

    return (
        <ReportContainer>
            {pages?.map((page, index) => <React.Fragment key={`page-${index}`}>
                {renderPage(page, index)}
                {index === 1 && !isMobile
                ? <Page>
                    <PageHeader title='Table of content' subtitle={!isMobile ? page.subheader : undefined} />
                    <PageContent>
                        <TableOfContent
                            pages={pages.filter(p => p.type === 'standard_page')}
                            showPlaceholder={!isMobile}
                        />
                    </PageContent>
                </Page>
                : <></>
                }
            </React.Fragment>
            )}
            <LastPage>
                <ReportLogo size='large' />
            </LastPage>
        </ReportContainer>
    );
};
