import { alpha, Box } from '@mui/material';
import { Tooltip, useTooltip } from '../charts/Tooltip';

export default function PerformanceBar({
  value,
  height = '12px',
  color1,
  hoverText = '',
} : {
  value: number;
  height?: number | string;
  color1: string;
  color2: string;
  hoverText?: string;
}) {
  const { tooltip, showTooltip, hideTooltip } = useTooltip();
  const borderLeftRadius = value < 100 ? 2 : 4;

  return <>
    <Box
      sx={{
        height,
        borderRadius: '6px',
        border: `0.2px solid ${alpha(color1, 0.6)}`,
        background: `radial-gradient(
          50% 50% at 50% 50%,
          ${alpha(color1, 0.15)} 0%,
          ${alpha(color1, 0.05)} 100%
        )`,
        padding: '1px',
        boxSizing: 'border-box',
      }}
      onMouseMove={(event: any) => {
        if (!hoverText) return
        const { pageX: x, pageY: y } = event;
        showTooltip(hoverText, {x, y});
      }}
      onMouseOut={() => hideTooltip()}
    >
      <Box
        sx={{
          background: `linear-gradient(
          -90deg,
          ${color1} 0%,
          ${alpha(color1, 0.75)} 100%
        )`,
          borderRadius: `4px ${borderLeftRadius}px ${borderLeftRadius}px 4px`,
          top: '1px',
          left: '1px',
          width: `${value}%`,
          height: '100%'
        }}
      />
    </Box>
    <Tooltip tooltip={tooltip} />
  </>;
};
