import { PaletteMode, PaletteOptions, createTheme } from "@mui/material";
import React from "react";
import { CustomColors } from "./custom-colors";
import {
  darkModeApplicationPalette,
  lightModeApplicationPalette,
} from "./palettes";

export const PaletteModeContext = React.createContext({ togglePaletteMode: () => {} });

declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'];
    backgrounds: {
      primary: string;
      secondary: string;
      tertiary: string;
      code: string;
      bagde1: string;
    };
    colors: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }

  interface PaletteOptions {
    disabled?: PaletteOptions['primary'];
    backgrounds?: {
      primary: string;
      secondary: string;
      tertiary: string,
      code: string;
      bagde1: string;
    };
    colors?: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    disabled: true;
  }
};

const getPalette = (mode: PaletteMode = 'light', customer = undefined): PaletteOptions => {
  if (mode === 'dark') return darkModeApplicationPalette;
  return lightModeApplicationPalette;
};

export const createCustomTheme = (mode: PaletteMode = 'light', customer = undefined) => {
  return createTheme({
    palette: getPalette(mode, customer),
    typography: {
      fontFamily: "Gilroy",
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            borderRadius: '3px',
            textTransform: 'unset',
          },
        },
      },
      MuiCircularProgress: {
        variants: [
          {
            props: { 
              size: 'small',
            },
            style: {
              width: '30px',
              height: '30px',
            },
          }
        ]
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
        },
      },
    },
  });
}
