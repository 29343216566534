import { useCallback } from "react";
import { ListItemIconButton } from "../elements/ListItemIconButton";
import { config } from "../../config";
import { useNavigate, useRevalidator } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api/twelve";
import { BaseContext } from "../../models";
import { useDispatch } from "react-redux";
import { addConversation } from "../../store/conversationsReducer";
import { Button, useTheme } from "@mui/material";

function createDummyContext(): BaseContext {
  const context: BaseContext = {
    version: "1",
  };
  return context;
}

export function CreateConversationButton({
  title = "New Chat",
  variant = 'Button',
} : {
  title?: string,
  variant?: 'Button' | 'ListItemIconButton'
}) {
  const revalidator = useRevalidator();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleCreate = useCallback(() => {
    const fn = async () => {
      const conversationName = config.conversationNaming.default;
      const { id: conversationId } = await dispatch(await addConversation(await getAccessTokenSilently(), conversationName));
      await api.createChat(
        await getAccessTokenSilently(),
        conversationId,
        conversationName,
        "RECEPTIONIST",
        createDummyContext()
      );
      revalidator.revalidate();
      navigate(`/conversation/${conversationId}`);
    };
    fn();
  }, [getAccessTokenSilently, navigate, dispatch, revalidator]);

  return <>
      {variant === 'Button'
        ? <Button
            onClick={handleCreate}
            variant="contained"
            sx={{
              minWidth: '240px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '8px 16px'
            }}
          >
            {title}
            <svg width="20" height="21" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 21.0001C1.45 21.0001 0.979167 20.8043 0.5875 20.4126C0.195833 20.0209 0 19.5501 0 19.0001V5.0001C0 4.4501 0.195833 3.97926 0.5875 3.5876C0.979167 3.19593 1.45 3.0001 2 3.0001H10.925L8.925 5.0001H2V19.0001H16V12.0501L18 10.0501V19.0001C18 19.5501 17.8042 20.0209 17.4125 20.4126C17.0208 20.8043 16.55 21.0001 16 21.0001H2ZM6 15.0001V10.7501L15.175 1.5751C15.375 1.3751 15.6 1.2251 15.85 1.1251C16.1 1.0251 16.35 0.975098 16.6 0.975098C16.8667 0.975098 17.1208 1.0251 17.3625 1.1251C17.6042 1.2251 17.825 1.3751 18.025 1.5751L19.425 3.0001C19.6083 3.2001 19.75 3.42093 19.85 3.6626C19.95 3.90426 20 4.1501 20 4.4001C20 4.6501 19.9542 4.89593 19.8625 5.1376C19.7708 5.37926 19.625 5.6001 19.425 5.8001L10.25 15.0001H6ZM8 13.0001H9.4L15.2 7.2001L14.5 6.5001L13.775 5.8001L8 11.5751V13.0001Z"
                  fill={theme.palette.primary.contrastText}
                />
            </svg>
          </Button>
        : <ListItemIconButton onClick={handleCreate}>{title}</ListItemIconButton>
      }
  </>
}