import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Tooltip, useTooltip } from './Tooltip';
import { BaseChartProps, ChartBody, ChartContainer, ChartFooter, ChartHeader } from './ChartLayout';
import { styled, useTheme, alpha } from '@mui/material';

interface DistributionChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    range?: [number, number];
    metrics: Array<{
      name: string;
      display_name: string;
      description: string;
    }>;
    series: Array<{
        name?: string;
        hoverStrings: Array<Array<string>>;
        values: Array<Array<number>>;
        marker: {
          variant: string;
          color?: string;
        };
    }>;
    labels?: Array<string>;
};

const StyledFooterText = styled("div")(({ theme }) => ({
  color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  }
}));

const StyledFooterBox = styled("div")(({ theme }) => ({
  color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
  }
}));

function SvgMarker({value, x, y, variant, color, height}: {
  value: number,
  x: number,
  y: number,
  variant: string,
  color?: string,
  height?:number,
}) {
  switch (variant) {
      case 'line': {
        const h = height ? height / 2 : 5;
        return (<line
          x1={x}
          y1={y-h}
          x2={x}
          y2={y+h}
          strokeWidth={2.5}
          stroke={color}
          strokeOpacity={0.75}
        />);
      };
      case 'circle':
        return (<circle
          cx={x}
          cy={y}
          r={6}
          strokeWidth={1}
          stroke={color}
          fill={color}
          fillOpacity={0.75}
        />);
      default: return <></>;
    }
};

function HtmlMarker({variant, color, height}: {
  variant: string,
  color?: string,
  height?:number,
}) {
  switch (variant) {
      case 'line':
        return <div
          style={{width: '2px', height: `${height || 18}px`, backgroundColor: `${color}` }}
        ></div>;
      case 'circle':
        return <div
          style={{width: `10px`, minWidth: '10px', height: `10px`, borderRadius: "50%", backgroundColor: `${color}` }}
        ></div>;
      default: return <></>;
    }
};

export default function DistributionChart(props: DistributionChartProps) {
  const theme = useTheme();
  const { tooltip, showTooltip, hideTooltip } = useTooltip();
  const {
    range = [-1, 1],
    size,
    labels = ["Worse", "Average", "Better"]
  } = props;
  const ref = useRef(null);
  const width: number = 560;
  const height: number = 460;
  const padding = {
    top: 32,
    bottom: 24,
    left: 32,
    right: 32
  };
  const plotPadding = 0;
  const plotAreaHeight: number = height - padding.top - padding.bottom;

  useEffect(() => {
  }, []);

  return <>
    <ChartContainer {...props}>
      <ChartHeader {...props} />
      <ChartBody size={size}>
        <svg ref={ref} width={'100%'} height={'100%'} viewBox={`0 0 ${width} ${height}`}>
          <g>{
            props.metrics.map((metric, metricIndex) => {
              const metricElementHeight = plotAreaHeight/props.metrics.length;
              const y = height - padding.bottom - metricElementHeight/2 - metricIndex*metricElementHeight;
              const xScale = d3.scaleLinear()
                  .domain(range)
                  .range([padding.left + plotPadding, width - padding.right - plotPadding]);

              return <g key={`metric-${metricIndex}`}>
                <line
                  x1={padding.left + plotPadding}
                  y1={y}
                  x2={width - padding.right - plotPadding}
                  y2={y}
                  strokeWidth={1}
                  stroke={theme.palette.customColors.dataPoints[1]}
                >
                </line>
                <text
                  x={width/2}
                  y={y - Math.min(24, metricElementHeight/2)}
                  fill={alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary)}
                  fontWeight={400}
                  textAnchor='middle'
                  alignmentBaseline='middle'
                  fontSize={'16px'}
                  onMouseMove={(event: any) => {
                    const { pageX: x, pageY: y } = event;
                    showTooltip(`${metric.display_name}<br/>${metric.description}`, {x, y});
                  }}
                  onMouseOut={() => hideTooltip()}
                >
                  {metric.display_name}
                </text>
                {
                  range.map((tick: any, tickIndex: number) => (
                    <text
                      key={`metric-${metricIndex}-tick-${tickIndex}`}
                      x={xScale(tick)}
                      y={y - 8}
                      fill={'none'}
                      fontWeight={600}
                      textAnchor='middle'
                      alignmentBaseline='middle'
                      fontSize={'10px'}
                    >
                      {tick}
                    </text>
                  ))
                }
                {props.series.map((serie, serieIdex) => (
                    serie.values.map((value, valueIndex) => (
                      <g
                        key={`serie-${serieIdex}-marker-${valueIndex}`}
                        onMouseMove={(event: any) => {
                          const { pageX: x, pageY: y } = event;
                          showTooltip(serie.hoverStrings[valueIndex][metricIndex]?.replaceAll('\n', '<br/>'), {x, y});
                        }}
                        onMouseOut={() => hideTooltip()}
                      >
                        <SvgMarker
                          value={value[metricIndex]}
                          x={xScale(value[metricIndex])}
                          y={y}
                          {...serie.marker}
                          height={10}
                        />
                      </g>
                    ))
                  )
                )}
              </g>;
            })
          }</g>
          {
            labels
            &&
            <g>
            {labels.map((label, index) => {
                const textAnchor = ["start", "middle", "end"];
                const x = [padding.left, width/2, width-padding.right];
                return <text
                    key={`label-${index}`}
                    x={x[index]}
                    y={height - 10}
                    fill={alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary)}
                    textAnchor={textAnchor[index]}
                    fontSize={'16px'}
                    fontWeight={200}
                    opacity={0.6}
                >
                    {label}
                </text>
            })}
            </g>
          }
        </svg>
      </ChartBody>
      <ChartFooter size={size}>
        <StyledFooterBox sx={{ gap: '16px'}}>
          {props.series.map((serie, index) =>
            <StyledFooterBox key={`legend-${index}`}>
              <HtmlMarker {...serie.marker} />
              <StyledFooterText>{serie.name}</StyledFooterText>
            </StyledFooterBox>)
          }
        </StyledFooterBox>
      </ChartFooter>
    </ChartContainer>
    <Tooltip tooltip={tooltip} />
  </>;
}