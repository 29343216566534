import { SVGLineElementAttributes } from 'react';

interface PlayerPositionChartProps extends SVGLineElementAttributes<any> {
  positions: Array<{name: string; isMain?: boolean}>;
};

export function PlayerPositionChart(props: PlayerPositionChartProps) {
  const strokeWidth = 0.3;
  const strokeColor = '#FFFFFF';
  const width: number = 315;
  const height: number = 215;
  const plotPadding = 0;
  const x = (value: number) => 1.05*value;
  const y = (value: number) => 0.68*(100 - value);

  const positionMap: Record<string, {x: number, y: number}> = {
    'STRIKER': {x: x(49), y: y(49)},
    'MIDFIELDER': {x: x(37), y: y(49)},
    'WINGER': {x: x(37), y: y(49)},
    'CENTRAL DEFENDER': {x: x(20.5), y: y(49)},
    'FULL BACK': {x: x(20.5), y: y(49)},
  };

  return <svg {...props} width={width/2} height={height} viewBox={`0 0 ${width/2} ${height}`} fontFamily='Poppins'>
    <g transform={`translate(${plotPadding}, 0)`}>
      <svg viewBox={`0 0 ${(105 + 2*3 + .3)/2} ${68 + 2*1}`} width={width/2 - 2*plotPadding}>
        <g transform={`translate(3,1)`}>
          {/* Pitch bounds */}
          <path d='M 0 0 H 105 V 68 H 0 Z' fill='none' stroke={strokeColor} strokeWidth={strokeWidth} />
          {/* Halfway line */}
          <line x1={x(50)} y1={y(0)} x2={x(50)} y2={y(100)} fill='none' stroke={strokeColor} strokeWidth={strokeWidth} />
          {/* Center circle */}
          <circle cx={x(50)} cy={y(50)} r={9.15} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
          {/* Center spot */}
          <circle cx={x(50)} cy={y(50)} r={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
          {/* Left penalty area */}
          <rect x={x(0)} y={y(81)} width={x(16)} height={y(19) - y(81)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
          {/* Left goal area */}
          <rect x={x(0)} y={y(62)} width={x(6)} height={y(38) - y(62)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
          <rect x={-2} y={34 - 7.32/2} width={2} height={7.32} fill={strokeColor} fillOpacity={.5} />
          {/* Left penalty spot */}
          <circle cx={x(11)} cy={y(50)} r={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} fill={strokeColor} />
          {/* Left penalty arc */}
          <path d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z" transform="translate(11.235,34) rotate(0)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
          {/* Corner arcs */}
          <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(0,68) rotate(0)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
          <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(0,0) rotate(90)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
        </g>
        <defs>
          <radialGradient id="main-position-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" style={{ stopColor: strokeColor, stopOpacity: 0.5 }}/>
            <stop offset="100%" style={{ stopColor: strokeColor, stopOpacity: 1 }}/>
          </radialGradient>
        </defs>
        {props.positions.map((position, index) =>
          <circle
            key={`position-${index}`}
            cx={positionMap[position.name]?.x}
            cy={positionMap[position.name]?.y}
            r={2}
            strokeWidth={0.5}
            stroke={strokeColor}
            fill="url(#main-position-gradient)"
          />
        )}
      </svg>
    </g>
  </svg>
}