import { Config } from "./type"

export const prod: Config = {
    api: {
        url: `https://gpt-api.twelve.football`,
    },
    auth0: {
        domain: "twelve-gpt.eu.auth0.com",
        clientId: "yrUrzYLjBH6JCRMi7RSm9mCR5MHCKrKu",
        audience: "https://gpt-api.twelve.football/"
    },
    guest_token: "07452e4c-ad48-4810-a755-d4eab95d6357",
}