import { PaletteOptions } from "@mui/material";
import {
    defaultCustomColors,
    club1CustomColors,
} from "./custom-colors";

export const lightModeApplicationPalette: PaletteOptions = {
    mode: 'light',
    primary: {
      main: "#014633",
      dark: "#002C1C",
      light: "#014633",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FA4A02",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF0000",
    },
    warning: {
      main: "#FFA726",
    },
    info: {
      main: "#5A657E",
    },
    success: {
      main: "#74A98E",
    },
    text: {
      primary: "#000000",
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.26)',
    },
    backgrounds: {
        primary: "#0000000a",
        secondary: "#0000000a",
        tertiary: "rgba(255, 255, 255, 0.08)",
        code: "#6f6f6",
        bagde1: "#E3F2FD",
    },
    colors: {
        accent: "#FF4B00",
        bagde1: "#0288D1",
    },
    customColors: defaultCustomColors,
};

export const darkModeApplicationPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
      main: "#000",
      dark: "#000",
      light: "#fff",
    },
    secondary: {
      main: "#FA4A02",
      contrastText: "#fff",
    },
    error: {
      main: "#ff0000",
    },
    warning: {
      main: "#ffa726",
    },
    info: {
      main: "#5A657E",
    },
    success: {
      main: "#74A98E",
    },
    text: {
      primary: "#fff",
      secondary: "rgb(255, 75, 0)",
    },
    disabled: {
      main: 'rgba(255, 255, 255, 0.26)',
    },
    background: {
        paper: "#1d1d1d",
        default: '#1f1f1f',
    },
    backgrounds: {
        primary: "#1d1d1d",
        secondary: "#1a1a1a",
        tertiary: "rgba(255, 255, 255, 0.08)",
        code: "#6f6f6",
        bagde1: "#101010",
    },
    colors: {
        bagde1: "#0288D1",
    },
    customColors: club1CustomColors,
};