const OPACITY = {
    text: {
        primary: 0.95,
        secondary: 0.65,
        disabled: 0.35,
    },
};

export type CustomColors = {
    readonly opacity: {
        text: {
            primary: number;
            secondary: number;
            disabled: number;
        },
    },
    report: {
        background: string;
    };
    visualisation: {
        background1: string;
        background2: string;
    };
    teamColors: {
        main: string;
        opposing: string;
    };
    accent: string;
    text: string;
    dataPoints: Array<string>;
    section: {
        background: string;
        gradient: string;
    };
    contrast: {
        color1: string;
    };
    tooltip: {
        background: string;
    };
};

export const defaultCustomColors: CustomColors = {
    opacity: OPACITY,
    report: {
        background: "#00140D",
    },
    visualisation: {
        background1: "#002115",
        background2: "#002C1C",
    },
    teamColors: {
        main: "#009940",
        opposing: "#FFFFFF",
    },
    accent: "#FF4B00",
    text: "#FFFFFF",
    dataPoints: [
        "#FFFFFF",
        "#009940",
        "#FFCC00",
        "#0095FF",
        "#FF4B00",
        "#8000FF",
        "#FF0000",
        "#0019F6",
        "#77001D",
        "#FF00A8",
    ],
    section: {
        background: "#014633",
        gradient: "#FFFFFF",
    },
    contrast: {
        color1: "#EEEEEE",
    },
    tooltip: {
        background: "#616161",
    },
};

export const club1CustomColors: CustomColors = {
    opacity: OPACITY,
    report: {
        background: "#484848",
    },
    visualisation: {
        background1: "#212121",
        background2: "#484848",
    },
    teamColors: {
        main: "#009940",
        opposing: "#FFFFFF",
    },
    accent: "#FFFFFF",
    text: "#FFFFFF",
    dataPoints: [
        "#FFFFFF",
        "#77001D",
        "#FFCC00",
        "#0095FF",
        "#FF4B00",
        "#8000FF",
        "#FF0000",
        "#0019F6",
        "#77001D",
        "#FF00A8",
    ],
    section: {
        background: "#484848",
        gradient: "#FFFFFF",
    },
    contrast: {
        color1: "#EEEEEE",
    },
    tooltip: {
        background: "#616161",
    },
};