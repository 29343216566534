import { Tooltip, useTooltip } from './Tooltip';
import { BaseChartProps, PitchChartWrapper } from './ChartLayout';
import { ChartBody, ChartContainer, ChartFooter, ChartHeader } from './ChartLayout';
import { useTheme, alpha, styled } from '@mui/material';
import { PitchChartZone } from '../../api/twelve';

interface FullPitchChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    labels?: Array<string>;
    footerText?: string;
    data: any;
};

const StyledFooterText = styled("div")(({ theme }) => ({
  color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.disabled),
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  }
}));

export function FullPitchChart(props: FullPitchChartProps) {
  const theme = useTheme();
  const { tooltip, showTooltip, hideTooltip } = useTooltip();
  const { size } = props;

  const x = (value: number) => value*1.05;
  const y = (value: number) => value*0.68;

  return <>
    <ChartContainer {...props} height={size === 'sm' ? 'unset' : 'auto'} >
      <ChartHeader {...props} />
      <ChartBody
        size={size}
        sx={{
          padding: "0 10px",
          flexGrow: 1,
          justifyContent: 'space-around',
          maxHeight: `${size === 'sm' ? '340px' : 'unset'}`
        }}
      >
        <PitchChartWrapper {...props} backgroundLikeGrid={false}>
          <g>{
            props.data.map((item: PitchChartZone, index: number) => {
              const zx = x(item.bounding_box.x_min);
              const zy = y(100 - item.bounding_box.y_max);
              const width = x(item.bounding_box.x_max - item.bounding_box.x_min);
              const height = y(item.bounding_box.y_max - item.bounding_box.y_min);
              return <g
                key={`zone-${index}`}
                onMouseMove={(event: any) => {
                  const { pageX: x, pageY: y } = event;
                  showTooltip(item.hover_text?.replaceAll('\n', '<br/>'), {x, y});
                }}
                onMouseOut={() => hideTooltip()}
              >
                <defs>
                  <radialGradient id={`zone-gradient-${index}`} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor={alpha(theme.palette.customColors.teamColors.main, 0.5)} />
                    <stop offset="100%" stopColor={theme.palette.customColors.teamColors.main} />
                  </radialGradient>
                </defs>
                <rect
                  x={zx}
                  y={zy}
                  width={width}
                  height={height}
                  fill={`url(#zone-gradient-${index})`}
                  fillOpacity={item.color_value}
                >
                </rect>
                {(item.value_z! < -1.5 || item.value_z! > 1.5) && <text
                  x={zx + width/2}
                  y={zy + height/2}
                  fill={alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.secondary)}
                  alignmentBaseline='middle'
                  textAnchor='middle'
                  fontSize={'3px'}
                  fontWeight={700}
                  transform={item.name?.includes("penalty area") ? `rotate(90,${zx + width/2},${zy + height/2})` : 'rotate(0)'}
                >
                  {item.annotation}
                </text>}
              </g>;
            })
          }</g>
        </PitchChartWrapper>
        {props.footerText
        ? <ChartFooter
            size={size}
            sx={{
              background: 'transparent!important',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <StyledFooterText sx={size === 'sm' ? {fontSize: '12px'} : {}}>
              {props.footerText}
            </StyledFooterText>
          </ChartFooter>
        : <></>
      }
      </ChartBody>
    </ChartContainer>
    <Tooltip tooltip={tooltip} />
  </>;
}