import { Box, IconButton, styled } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useState, useRef, useEffect } from "react";
import TextLoader from "./TextLoader";
import { TextareaAutosize as BaseTextareaAutosize, TextareaAutosizeProps } from '@mui/base/TextareaAutosize';
import SendIcon from '@mui/icons-material/Send';

type InputAreaProps = {
  disabled?: boolean;
  loading?: boolean;
  onSend: (text: string) => void;
};

const TextareaAutosize = styled(BaseTextareaAutosize)<TextareaAutosizeProps>(
  ({ theme }) => `
  min-height: 30px;
  font-family: ${theme.typography.fontFamily};
  font-size: 16px;
  background: transparent;
  border: none;
  width: 100%;
  resize: none;
  outline: 0;
  padding: 0px 0px 0px 10px;
  color: ${theme.palette.text.primary};
  &::placeholder {
    color: ${theme.palette.disabled.main};
  }
`,
);

export default function InputArea(props: InputAreaProps) {
  const {
    onSend,
    loading,
  } = props;
  const [text, setText] = useState("");  
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [loading]);

  const onClick = () => {
    onSend(text);
    setText("");
  };

  const onChange = (e: any) => {
    setText(e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onClick();
    }
  };

  return (
    <Box sx={{
      flexGrow: 1,
      boxSizing: 'border-box',
      maxWidth: '800px',
      minHeight: '56px',
      display: 'flex',
      alignItems: 'center',
    }}>
      {loading ? (
        <TextLoader />
      ) : (
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-end',
          boxSizing: 'border-box',
          borderRadius: '32px',
          padding: '8px 16px',
          backgroundColor: theme.palette.backgrounds.secondary,
          width: '100%',
        }}>
          <TextareaAutosize
            ref={textareaRef}
            value={text}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder="Send a message to TwelveGPT"
          />
          <IconButton color="inherit" onClick={onClick} disabled={!text?.trim()}>
            <SendIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}