import { BaseChartProps, ChartBody, ChartContainer, ChartFooter, ChartHeader } from "./ChartLayout";
import * as d3 from 'd3';
import { Tooltip, useTooltip } from "./Tooltip";
import MarkerProps from "./markers/MarkerProps";
import { Box, useTheme, alpha } from "@mui/material";

interface ScatterChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    series: Array<{
        x: Array<number>;
        y: Array<number>;
        name?: string;
        color?: string;
        markers?: Array<React.FunctionComponent<MarkerProps> | undefined | null>;
        variant?: 'line' | 'area';
        opacity?: number;
    }>;
    xDomain: [number, number];
    xAxis?: {
        labels?: Array<{title: string, x: number}>,
        markers?: Array<{
            position: 'top' | 'bottom',
            x: number,
            marker: React.FunctionComponent<MarkerProps>,
            color?: string,
            tooltip?: string;
        }>;
    };
    yAxis?: {
        labels?: Array<{title: string, y: number}>;
    };
    unit?: string;
    width?: number;
    height?: number;
    showValues?: boolean;
    showLegends?: boolean;
};

export default function ScatterChart(props: ScatterChartProps) {
    const {
        series = [],
        xAxis = {},
        xDomain,
        yAxis,
        unit = '',
        showValues = true,
        showLegends = false,
    } = props;

    const theme = useTheme();
    const { tooltip, showTooltip, hideTooltip } = useTooltip();
    const colors = theme.palette.customColors;
    const textPrimaryColor = alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary);
    const textSecondaryColor = alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary);

    const width: number = 493;
    const height: number = 452;
    const padding = {
        top: 0,
        bottom: 0,
        left: yAxis?.labels?.length ? 60 : 38,
        right: 38
    };
    const xAxisHeight = 88;
    const marker = {
        width: 64,
        height: 40
    };

    const yDomain: any = d3.extent(series.reduce((p: Array<number>, c) => { p.push(...c.y); return p;}, []));

    const xScale = (x: number) => {
        return d3.scaleLinear()
            .domain(xDomain)
            .range([padding.left, width - padding.right])(x);
    };

    const yScale = (y: number) => {
        return d3.scaleLinear()
            .domain(yDomain)
            .range([height - padding.bottom - xAxisHeight - (showValues ? 20 : 0), padding.top + marker.height/2])(y);
    };

    const opacityScale = (value: number) => {
        return d3.scaleLinear()
            .domain(yDomain)
            .range([0.4, 1])(value);
    };

    const areaPath = d3.area()
        .x((d) => xScale(d[0]))
        .y0(yScale(yDomain[0]))
        .y1((d) => yScale(d[1]))
        .curve(d3.curveStepAfter);

    return (<>
        <ChartContainer {...props}>
            <ChartHeader {...props}/>
            <ChartBody>
                <svg
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                >
                    <line
                        x1={padding.left}
                        y1={height - padding.bottom - xAxisHeight/2}
                        x2={width - padding.right}
                        y2={height - padding.bottom - xAxisHeight/2}
                        stroke={textSecondaryColor}
                        strokeDasharray={"3 3"}
                        strokeWidth={.75}
                    />
                    {xAxis?.labels?.map((label, index) => {
                        const x = xScale(label.x);
                        const y = height - padding.bottom - xAxisHeight/2;
                        const w = label.title.length > 3 ? 44 : 22;
                        const h = 20;
                        return <g key={`x-${index}`}>
                        <rect
                            x={x - w/2}
                            y={y - h/2}
                            rx={h/2}
                            width={w}
                            height={h}
                            fill={textPrimaryColor}
                        />
                        <text
                            x={x}
                            y={y}
                            textAnchor="middle"
                            dy=".35em"
                            fill={textSecondaryColor}
                            fontSize="12px"
                        >
                            {label.title}
                        </text>
                    </g>})}
                    {xAxis.markers?.map((marker, index) => {
                        const x = xScale(marker.x);
                        const y = marker.position === 'top' ? height - padding.bottom - 0.75*xAxisHeight - 10 : height - padding.bottom - 0.25*xAxisHeight + 10;
                        return <g key={`markers-top-${index}`}
                            onMouseMove={(event: any) => {
                                if (!marker.tooltip) return;
                                const { pageX: x, pageY: y } = event;
                                showTooltip(marker.tooltip, {x, y});
                            }}
                            onMouseOut={() => hideTooltip()}
                        >
                            {marker.marker({
                                x: x,
                                y: y,
                                color: marker.color,
                                bgColor: textPrimaryColor
                            })}
                        </g>
                    })}
                    {series.map((serie, serieIndex) => {
                        const serieColor = serie.color || colors.dataPoints[serieIndex];
                        return <g key={`serie-${serieIndex}`}>
                            {serie.variant === 'area'
                            ?  <path
                                    d={`${areaPath(serie.y.map((y, index) => ([serie.x[index], y])))}`}
                                    fill={serieColor}
                                    fillOpacity={serie.opacity || 1 - .2*serieIndex}
                                />
                            :  <path
                                    d={`M${serie.y.map((value, index) => `${xScale(serie.x[index])} ${yScale(value)}`)}`}
                                    fill="none"
                                    stroke={serieColor}
                                    strokeDasharray="3 3"
                                    strokeWidth={1}
                                />
                            }
                            {showValues && serie.y.map((value, index) => {
                                const x = xScale(serie.x[index]);
                                const y = yScale(value);
                                const w = 60;
                                const h = 36;
                                const fillColor = d3.color(textSecondaryColor) as d3.RGBColor;
                                const fillOpacity = opacityScale(value);
                                return <g key={`serie-${serieIndex}-y-${index}`}
                                    onMouseMove={(event: any) => {
                                        const { pageX: x, pageY: y } = event;
                                        showTooltip(`Value: ${value.toFixed(2)}`, {x, y});
                                    }}
                                    onMouseOut={() => hideTooltip()}
                                    >
                                    <rect
                                        x={x - w/2}
                                        y={y - h/2}
                                        width={w}
                                        height={h}
                                        fill={d3.rgb(fillColor.r * fillOpacity, fillColor.g * fillOpacity, fillColor.b * fillOpacity).formatHex()}
                                        //fillOpacity={opacityScale(value)}
                                        rx={8}
                                    />
                                    <text
                                        x={x}
                                        y={y}
                                        textAnchor="middle"
                                        dy=".35em"
                                        fill={textPrimaryColor}
                                        fontSize="12px"
                                    >
                                        {value.toFixed(2)}{unit}
                                    </text>
                                </g>
                            })}
                        </g>;
                    })}
                    {series.map((serie, serieIndex) => {
                        const serieColor = serie.color || colors.dataPoints[serieIndex];
                        return <g>
                            {serie.markers?.map((marker, index) => {
                                const x = xScale(serie.x[index]);
                                const y = yScale(serie.y[index]);
                                return <g key={`marker-value-${index}`}
                                    onMouseMove={(event: any) => {
                                        //if (!marker.tooltip) return;
                                        const { pageX: x, pageY: y } = event;
                                        showTooltip(`Value: ${serie.y[index].toFixed(2)}`, {x, y});
                                    }}
                                    onMouseOut={() => hideTooltip()}
                                >
                                    {marker ? marker({
                                        x: x,
                                        y: y,
                                        color: serieColor,
                                    }) : <></>}
                                </g>;
                            })}
                        </g>
                    })}
                    {yAxis?.labels?.map((label, index) => {
                        const x = 10;
                        const y = yScale(label.y);
                        return <g key={`y-${index}`}>
                        <text
                            x={x}
                            y={y}
                            textAnchor="start"
                            dy=".35em"
                            fill={textSecondaryColor}
                            fontSize="12px"
                        >
                            {label.title}
                        </text>
                    </g>})}
                </svg>
            </ChartBody>
            {showLegends ? <ChartFooter>
                <Box sx={{display:'flex', flexDirection: 'row', gap: '16px'}}>
                    {series.map((serie, index) =>
                        <Box key={`legend-${index}`} sx={{ gap: '8px', display: 'flex', alignItems: 'center' }}>
                            <div style={{width: '32px', height: '3px', backgroundColor: serie.color || colors.dataPoints[index] }}></div>
                            <div style={{
                                color: textPrimaryColor,
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '14px',
                                }
                            }}>{serie.name}</div>
                        </Box>
                    )}
                </Box>
            </ChartFooter> : <></>}
        </ChartContainer>
        <Tooltip tooltip={tooltip} />
    </>);
};