import {
    Box,
    styled,
    BoxProps,
    Typography,
    TypographyProps,
    Avatar,
    AvatarProps,
    useTheme,
    alpha,
} from '@mui/material';
import ReportLogo from './ReportLogo';
import { ReactElement } from 'react';

export const ReportContainer = styled(Box)<BoxProps>(({ theme }) => ({
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.customColors.report.background,
}));

export const BasicPage = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        height: 'unset',
    },
}));

export const TitlePage = styled(Box)<BoxProps>(({ theme }) => ({
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        minHeight: '680px',
    },
    // background: `
    //     url(/images/report-main-placeholder.png) center no-repeat
    // `,
}));

export const LastPage = styled(Box)<BoxProps>(({ theme }) => ({
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    backgroundColor: theme.palette.customColors.report.background,
    marginTop: '16px',
    [theme.breakpoints.up('md')]: {
        minHeight: '680px',
    },
}));

export const Page = styled(BasicPage)<BoxProps>(({ theme }) => ({
    padding: '8px 64px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '32px',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '16px',
        borderRadius: '4px',
        background: `var(--Section, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.08) 100%), ${theme.palette.customColors.section.background});`,
    },
    [theme.breakpoints.down('md')]: {
        padding: '8px 12px',
        '&::after': {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        minHeight: '680px',
    },
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.customColors.accent,
    fontSize: '34px',
    fontWeight: 700,
    letterSpacing: '0.25px',
    lineHeight: '120%',
}));

export const SubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.15px',
    lineHeight: '120%',
}));

export const PageHeader = function({
    title,
    subtitle,
    children,
}: {title?: string, subtitle?: string, children?: ReactElement }) {
    const theme  = useTheme();
    const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 0',
        minHeight: '48px',
        boxSizing: 'border-box',
        marginBottom: '16px',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            borderTop: `1px solid ${theme.palette.customColors.section.background}`,
            borderBottom: `1px solid ${theme.palette.customColors.section.background}`,
        },
    }));

    const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
        color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
        fontSize: '20px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    }));

    const StyledSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
        color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.secondary),
        fontSize: '20px',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
        },
    }));

    const StyledSeparator = styled(Box)<BoxProps>(({ theme }) => ({
        width: '1px',
        height: '24px',
        backgroundColor: theme.palette.customColors.section.background,
    }));

    return <StyledBox>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                borderRadius: '4px',
                padding: '16px',
                textTransform: 'uppercase',
                background: `var(--Section, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.08) 100%), ${theme.palette.customColors.section.background});`,
            },
        }}>
            <StyledTitle variant='caption'>{title}</StyledTitle>
            {subtitle && <>
                <StyledSeparator />
                <StyledSubTitle variant='caption'>{subtitle}</StyledSubTitle>
            </>}
            {children && <Box sx={{display: 'flex', flexGrow: 1, justifyContent: 'end'}}>
                {children}
            </Box>}
        </Box>
        <Box sx={{
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        }}>
            <ReportLogo style={{position: 'absolute', left: '-64px'}} />
        </Box>
    </StyledBox>
};

export const PageHeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '48px',
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    fontSize: '14px',
    fontWeight: 200,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '8px',
}));

export function PageHeaderProfile(props: { title: string, src?: string }) {
    const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '8px',
        padding: '24px',
        boxSizing: 'border-box',
        background: `linear-gradient(
            139deg,
            ${theme.palette.customColors.visualisation.background1} 0%,
            ${alpha(theme.palette.customColors.visualisation.background1, 0.95)} 100%),
            ${theme.palette.customColors.section.gradient}
        `,
        [theme.breakpoints.down('md')]: {
            marginTop: '8px',
            padding: '16px',
            background: `var(--Section, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.08) 100%), ${theme.palette.customColors.section.background});`,
        },
    }));

    const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
        color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
        fontSize: '48px',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
            fontWeight: 500,
        },
    }));

    const StyledAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
        width: '96px',
        height: '96px',
        [theme.breakpoints.down('md')]: {
            width: '64px',
            height: '64px',
        },
    }));

    const {
        title,
        src = '/images/profile.svg',
    } = props;

    return (
        <StyledBox>
            <StyledAvatar alt='Profile logo' src={src} />
            <StyledTypography variant='caption'>{title}</StyledTypography>
        </StyledBox>
    );
}

export const PageContent = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        overflow: 'unset',
    },
}));

export const PageBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px 0',
    borderRadius: '8px',
    background: `linear-gradient(
        139deg,
        ${theme.palette.customColors.visualisation.background1} 0%,
        ${alpha(theme.palette.customColors.visualisation.background1, 0.95)} 100%),
        ${theme.palette.customColors.section.gradient}
    `,
}));

export const StyledPageBoxHeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    fontSize: '18px',
    fontWeight: 700,
}));

export function PageBoxHeader(props: { title?: string, subTitle?: string }) {
    const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px',
        color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    }));

    const StyledSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
        fontSize: '16px',
        fontWeight: 600,
        opacity: 0.6,
    }));

    const {
        title,
        subTitle,
    } = props;

    return <StyledBox>
        <StyledPageBoxHeaderTitle variant='caption'>{title}</StyledPageBoxHeaderTitle>
        {subTitle && <StyledSubTitle variant='caption'>{subTitle}</StyledSubTitle>}
    </StyledBox>;
}

export const PageBoxContent = styled(Box)<BoxProps>(({ theme }) => ({
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    padding: '0 24px',
    overflow: 'auto',
    height: '100%',
}));