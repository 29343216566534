import React from "react";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
} from "react-router-dom";

type CustomRouterLinkProps = Omit<RouterLinkProps, 'to'> & {
    to: {
      pathname: string;
      state?: any;
    };
};

export const CustomRouterLink = React.forwardRef<HTMLAnchorElement, CustomRouterLinkProps>((props, ref) => {
    const { to, state, ...rest } = props;
    return <RouterLink ref={ref} to={to.pathname} state={to.state}  {...rest} />;
});