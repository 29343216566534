import React, { ReactNode, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidebar } from "../../store/viewReducer";
import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { RootState } from "../../store";
import { useAuth0 } from '@auth0/auth0-react';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

type Props = {
  children: ReactNode | Array<ReactNode>;
};

const Main = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  borderRight: `1px solid ${theme.palette.divider}`,
  width: '256px',
  height: 'calc(var(--vh, 1vh) * 100 - 64px)',
  top: '64px',
  background: theme.palette.background.default,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(var(--vh, 1vh) * 100 - 56px)',
    top: '56px',
  },
}));

const Footer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: '14px',
  height: '72px',
  padding: '16px',
  width: '100%',
  backgroundColor: theme.palette.backgrounds.primary,
  [theme.breakpoints.down('sm')]: {
  },
}));

export default function Sidebar({children}: Props) {
  const opened = useSelector((state: RootState) => state.view.sidebar.opened);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    user,
    logout,
  } = useAuth0();

  useState(() => {
    if (isMobile) dispatch(toggleSidebar(false));
  });

  return <>
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1100,
        display: isMobile && opened ? 'block' : 'none',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }}
      onClick={() => dispatch(toggleSidebar(false))}
    />
    <Box
      sx={{
        flexShrink: 0,
        position: isMobile ? 'fixed' : 'static',
        zIndex: 1200,
        transform: 'unset',
        marginLeft: opened ? '0' : '-256px',
        transition: theme.transitions.create(['all'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('md')]: {
          marginLeft: 'unset',
          transform: opened ? 'translateX(0)' : 'translateX(-100%)',
        },
      }}
    >
      <Main component="nav">
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden'
          }}
          onClick={() => {
            if (isMobile) {
              dispatch(toggleSidebar())
            }
          }}
        >
          {children}
        </Box>
        <Footer>
          <Avatar src={user?.picture} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              justifyContent: 'center',
              color: 'text.primary',
            }}
          >
            <Typography variant="body1" sx={{maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{user?.nickname}</Typography>
          </Box>
          <IconButton
            aria-label="settings"
            onClick={() =>  logout({
              logoutParams: {
                returnTo: window.location.origin
              }
            })}
          >
            <LogoutRoundedIcon />
          </IconButton>
        </Footer>
      </Main>
    </Box>
  </>;
}
