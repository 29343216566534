import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from "react-router-dom";
import { Box, Fab } from "@mui/material";
import Chat from "../components/chat/Chat";
import { api, ChatModel } from "../api/twelve";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { config } from "../config";
import { renameConversation } from "../store/conversationsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";


export default function Conversation() {
  const params = useParams<{conversationId: string}>();
  const [conversationId, setConversationId] = useState(0);
  const [data, setData] = useState<{ conversationId: number; chats: ChatModel[] }>({conversationId: 0,chats: []});
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const selectedConversationName = useSelector((state: RootState) => state.conversations.selectedConversation?.name);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const endRef = useRef<HTMLDivElement | null>(null);

  const fetchData = useCallback(async () => {
    if (!conversationId) return;
    const token = await getAccessTokenSilently();
    const chats = await api.listChats(token, conversationId);
    setData({conversationId,chats});
  }, [conversationId, getAccessTokenSilently]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  const onRedirect = async (chatId: number) => {
    fetchData();
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  useEffect(() => {
    setConversationId(Number(params.conversationId));
  }, [params.conversationId]);

  useEffect(() => {
    fetchData();
    setShowScrollButton(false);
  }, [fetchData]);

  useEffect(() => {
    const renameConversationIfPossible = async () => {
      if (selectedConversationName === config.conversationNaming.default) {
        if (data.chats.filter(c => c.analyst_name === "SCOUT")?.length > 0) {
          dispatch(renameConversation(await getAccessTokenSilently(), data.conversationId));
        }
      }
    }
    renameConversationIfPossible();
  }, [selectedConversationName, data, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (scrollRef.current && !showScrollButton) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });

    if (scrollRef.current) {
      observer.observe(scrollRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [scrollRef, showScrollButton]);

  return (
    <Box sx={{
      height: '100%',
      position: 'relative',
    }}>
      <Box sx={{
          width: '100%',
          height: 'calc(100% - 92px)',
          overflowX: 'clip',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary
        }}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {data.chats.map((c: ChatModel, i: number) => <Chat key={c.id} conversationId={data.conversationId} chat={c} hasInput={i >= (data.chats.length - 1)} onRedirect={onRedirect}/>)}
        <div ref={endRef} />
      </Box>

      {showScrollButton && (
        <Fab
          size="small"
          sx={{
            position: 'absolute',
            bottom: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          onClick={() => {
            if (endRef.current) {
              endRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <ArrowDownwardIcon />
        </Fab>
      )}
    </Box>
  );
}
