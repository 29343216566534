import {
  List,
  ListSubheader,
  Divider,
  styled,
  DividerProps,
  ListItem,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { api } from "../../api/twelve";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DateTimeHelper from "../../helpers/datetime.helper";
import { SavedReportList } from "../reports/SavedReportList";
import ConversationItem from "./ConversationItem";
import { ConversationModel } from "../../models";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { RootState } from "../../store";
import {
  deleteConversation,
  fetchConversations,
  fetchMoreConversations,
  selectConversation
} from "../../store/conversationsReducer";
import { CreateConversationButton } from "./CreateConversationButton";

const StyledDivider = styled(Divider)<DividerProps>(({theme}) => ({
  backgroundColor: theme.palette.divider,
  opacity: .4,
}));


function getCategorizedItems(items: Array<ConversationModel>): Record<string, Array<ConversationModel>> {
  const categorizedItems: {[key: string]: Array<ConversationModel>} = {
    'Today': [],
    'Yesterday': [],
    'Last 7 days': [],
    'Last 30 days': [],
  };

  items.forEach((item) => {
    if (DateTimeHelper.isToday(item.created_at)) {
      categorizedItems.Today = [...categorizedItems.Today, item];
    } else if (DateTimeHelper.isYesterday(item.created_at)) {
      categorizedItems.Yesterday = [...categorizedItems.Yesterday, item];
    } else if (DateTimeHelper.isLast7Days(item.created_at)) {
      categorizedItems['Last 7 days'] = [...categorizedItems['Last 7 days'], item];
    } else if (DateTimeHelper.isSameMonth(item.created_at)) {
      categorizedItems['Last 30 days'] = [...categorizedItems['Last 30 days'], item];
    } else {
      const name = DateTimeHelper.format(item.created_at, DateTimeHelper.isSameYear(item.created_at) ? 'MMMM' : 'MMMM YYYY');
      if (!categorizedItems[name]) {
        categorizedItems[name] = [];
      }
      categorizedItems[name] = [...categorizedItems[name], item];
    }
  });

  return categorizedItems; // Ensures all arrays are new references
}

export function ConversationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const mainListContainerRef = useRef<any>(null);
  const conversationListContainerRef = useRef<any>(null);
  const [categorizedItems, setCategorizedItems] = useState<Record<string, Array<ConversationModel>>>({});
  const { conversationId } = useParams<{ conversationId: string }>();
  const {
    loading,
    canLoadingMore,
    items,
  } = useSelector((state: RootState) => state.conversations);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCanLoadingMore, setIsCanLoadingMore] = useState<boolean>(false);
  const [sinceTimestamp, setSinceTimestamp] = useState<Date>()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIsCanLoadingMore(canLoadingMore);
  }, [canLoadingMore]);

  useEffect(() => {
    setSinceTimestamp(items[items.length - 1]?.created_at);
    const newCategorizedItems = getCategorizedItems(items);
    setCategorizedItems(newCategorizedItems);
  }, [items]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchConversations(await getAccessTokenSilently()));
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const onScroll = async (ref: React.MutableRefObject<any>) => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const token = await getAccessTokenSilently();
      if (scrollTop + clientHeight >= scrollHeight - 1 && isCanLoadingMore && !isLoading) {
        dispatch(fetchMoreConversations(token, sinceTimestamp))
      }
    }
  };

  const handleUpdateConversationName = useCallback(async (id: number, name: string) => {
    const updated_conversation = await api.updateConversationName(await getAccessTokenSilently(), id, name);
    dispatch(selectConversation(updated_conversation));
  }, [getAccessTokenSilently, dispatch]);

  const handleDelete = useCallback(async (id: number) => {
    dispatch(deleteConversation(await getAccessTokenSilently(), id));
    api.deleteInitializedChat(id);
    if (id === Number(conversationId)) {
      navigate('/');
    }
  }, [getAccessTokenSilently, navigate, conversationId, dispatch]);

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
        padding: 0,
      }}
      ref={mainListContainerRef}
      onScroll={() => onScroll(mainListContainerRef)}
    >
      <SavedReportList />
      <StyledDivider />
      <ListSubheader sx={{zIndex: 10, padding: 0}}>
        <CreateConversationButton variant="ListItemIconButton" />
      </ListSubheader>
      <List
        dense
        disablePadding
        sx={{
          overflow: isMobile ? 'unset' : 'auto'
        }}
        ref={conversationListContainerRef}
        onScroll={() => onScroll(conversationListContainerRef)}
      >
        {Object.keys(categorizedItems).map((category, categoryIndex) =>
          categorizedItems[category].length
          ? <React.Fragment key={`category-${categoryIndex}`}>
              <ListSubheader>{category}</ListSubheader>
              {
                categorizedItems[category].map((i) =>
                  <ConversationItem
                    key={`conversation-${i.id}`}
                    conversation={i}
                    selected={i.id === Number(conversationId)}
                    to={`/conversation/${i.id}`}
                    onUpdateConversationName={handleUpdateConversationName}
                    onDelete={handleDelete}
                  />
                )
              }
            </React.Fragment>
          : <React.Fragment key={`category-${categoryIndex}`} />
        )}
        {isLoading && <ListItem
            sx={{
              justifyContent: 'center'
            }}
          >
          <CircularProgress size="16px" />
        </ListItem>}
      </List>
    </List>
  );
}