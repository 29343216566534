import { styled, alpha } from "@mui/material";
import { BaseChartProps, ChartBody, ChartContainer, ChartFooter, ChartHeader, StyledBodyText } from "./ChartLayout";
import { useTheme } from '@mui/material/styles';
import { Tooltip, useTooltip } from './Tooltip';
import { SvgTextWrap } from "./common/SvgTextWrap";

interface RadarChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    bottomTitle?: string;
    axies: Array<string>;
    series: Array<{
        data: Array<number>;
        name?: string;
        color?: string,
        hover_strings: Array<string>
    }>;
    width?: number;
    height?: number;
    value_range?: [number, number];
    levelNumber?: number;
    playerName?: string;
};

const StyledLabel = styled(SvgTextWrap)(({ theme }) => ({
    stroke: "none",
    shapeRendering: "crispEdges",
    fontFamily: theme.typography.fontFamily,
    fill: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    fontWeight: 200,
    fontSize: '16px',
}));

const StyledFooterText = styled("div")(({ theme }) => ({
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
    }
  }));
  
const StyledFooterBox = styled("div")(({ theme }) => ({
    color: alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary),
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
    }
}));

export default function RadarChart(props: RadarChartProps) {
    const theme = useTheme();
    const {
        bottomTitle,
        axies,
        series,
        width = 496,
        height = 360,
        value_range = [0, 100],
        levelNumber = 7,
        size,
    } = props;
    const { tooltip, showTooltip, hideTooltip } = useTooltip();

    const radius = 130;
    const minValue = value_range[0];
    const maxValue = value_range[1];
    const angleSlice = Math.PI * 2 / axies.length;
    const xScale = (value: number, index: number) => {
        return ((value - minValue)* radius/(maxValue - minValue)) * Math.cos(angleSlice*index - Math.PI/2);
    }
    const yScale = (value: number, index: number) => {
        return ((value - minValue)* radius/(maxValue - minValue)) * Math.sin(angleSlice*index - Math.PI/2);
    }
    const textAnchor = (index: number) => {
        if (index === 0 || angleSlice * index === Math.PI) return "middle";
        return (angleSlice * index < Math.PI) ? "start" : "end";
    }

    return (<>
        <ChartContainer {...props}>
            <ChartHeader {...props} />
            <ChartBody size={size} sx={{padding: '0 8px'}}>
                <svg
                    style={{width: '100%', height: '100%'}}
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}>
                    <g 
                    transform={`translate(${width/2},${height/2})`}
                    >
                        <g className="axis-wrapper">
                            {Array.from({length: levelNumber + 1}, (level, index) => index).map((level, index) =>
                                <circle
                                    key={`level-${index}`}
                                    className="axis-grid-circle"
                                    r={radius/levelNumber*level}
                                    fill={'transparent'}
                                    stroke={level === 1 || level === 2 ? 'transparent' : theme.palette.customColors.contrast.color1}
                                    strokeOpacity={Array.from([0, 0, 0.06, 0.1, 0.14, 0.1, 0.15])[level - 1]}
                                    strokeWidth={2}
                                />
                            )}
                            {axies.map((axis, index) =>
                                <g key={`axis-${index}`}>
                                    <StyledLabel
                                        text={axis}
                                        dy="0.35em"
                                        fontSize="18px"
                                        fill={alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.primary)}
                                        x={xScale(maxValue * 1.12, index)}
                                        y={yScale(maxValue * (angleSlice * index === Math.PI ? 1.19 : 1.12), index)}
                                        textAnchor={textAnchor(index)}
                                        width={index === 0 || angleSlice*index === Math.PI ? 36 : 14}
                                    />
                                </g>
                            )}
                            {series.map((serie, serieIndex) => {
                                const x = serie.data.map((value, index) => xScale(value, index));
                                const y = serie.data.map((value, index) => yScale(value, index));
                                const max = Math.max(...serie.data);
                                const indexOfMax = serie.data.indexOf(max);
                                const radialGradientId = `radial-gradient-${serieIndex}`;
                                return <g
                                    key={`serie-${serieIndex}`}
                                >
                                    <defs>
                                        <radialGradient
                                            id={radialGradientId}
                                            r="100%"
                                            cx="50%"
                                            cy="50%"
                                            fx="50%"
                                            fy="50%"
                                        >
                                            <stop stopColor={serie.color} stopOpacity="0" />
                                            <stop offset="90%" stopColor={serie.color} stopOpacity="0.2"/>
                                        </radialGradient>
                                    </defs>
                                    <polygon
                                        stroke={serie.color}
                                        strokeWidth="2"
                                        strokeLinejoin="round"
                                        fill={`url(#${radialGradientId})`}
                                        points={`${serie.data.map((value, index) => `${x[index]}, ${y[index]}`)}`}
                                    />
                                    <circle
                                        id={`flare-${serieIndex}`}
                                        cx={xScale(max, indexOfMax)}
                                        cy={yScale(max, indexOfMax)}
                                        r={28}
                                        fill={theme.palette.customColors.contrast.color1}
                                        filter="blur(50px)"
                                    />
                                    {
                                        serie.data.map((value, dataIndex) => (
                                            <circle
                                                key={`serie-${serieIndex}-data-${dataIndex}`}
                                                cx={xScale(Math.min(maxValue, value), dataIndex)}
                                                cy={yScale(Math.min(maxValue, value), dataIndex)}
                                                r={8}
                                                fill={'transparent'}
                                                stroke={'transparent'}
                                                onMouseMove={(event: any) => {
                                                    const { pageX: x, pageY: y } = event;
                                                    showTooltip(serie.hover_strings[dataIndex], {x, y});
                                                }}
                                                onMouseOut={() => hideTooltip()}
                                            >
                                            </circle>
                                        ))
                                    }
                                </g>
                            })}
                        </g>
                    </g>
                </svg>
                {bottomTitle ? <StyledBodyText size={size}><>{bottomTitle}</></StyledBodyText> : <></>}
            </ChartBody>
            <ChartFooter size={size}>
                <StyledFooterBox sx={{ gap: '16px'}}>
                    {series.map((serie, index) =>
                        <StyledFooterBox key={`legend-${index}`}>
                            <div
                                style={{
                                    width: '32px',
                                    height: '3px',
                                    backgroundColor: serie.color
                                }}
                            />
                            <StyledFooterText>{serie.name}</StyledFooterText>
                        </StyledFooterBox>
                    )}
                </StyledFooterBox>
            </ChartFooter>
        </ChartContainer>
        <Tooltip tooltip={tooltip} />
    </>);
}
