import { alpha, Box, useTheme } from "@mui/material";
import {
    TitlePage as TitlePageContainer,
    Title,
    SubTitle,
} from "../ReportLayout";
import ReportLogo from "../ReportLogo";
import { ReportPage } from "../../../models";

export default function TitlePage({page}: {page: ReportPage}) {
    const theme = useTheme();
    return <TitlePageContainer>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            filter: 'blur(2px)',
            background: `
                url(/images/report-main-placeholder.png) center no-repeat
            `,
          }}
        />
         <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `
                linear-gradient(
                180deg, 
                ${theme.palette.customColors.report.background} 0%,
                ${theme.palette.customColors.visualisation.background1} 10%,
                ${theme.palette.customColors.visualisation.background1} 25%,
                ${alpha(theme.palette.customColors.visualisation.background2, 0.85)} 100%
            )`,
            backdropFilter: "blur(4px)",
          }}
        />
        <Box sx={{position: 'absolute', top: '32px', right: '32px' }}>
            <ReportLogo />
        </Box>
        <Box sx={{
            width: '100%',
            position: 'absolute',
            left: "50%",
            top: '35%',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px'
        }}>
            <Title variant='caption'>{page?.title}</Title>
            <SubTitle variant='caption'>{page?.subtitle}</SubTitle>
            <SubTitle variant='caption'>{page?.subsubtitle}</SubTitle>
            <SubTitle variant='caption' sx={{color: (theme) => alpha(theme.palette.customColors.text, theme.palette.customColors.opacity.text.secondary)}}>{page?.subsubsubtitle}</SubTitle>
        </Box>
    </TitlePageContainer>
}