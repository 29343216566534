import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Box,
    //Button,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
    //styled,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useAuth0 } from '@auth0/auth0-react';
import { ReportType } from "../models";
import PageLoader from "../components/elements/PageLoader";
import PersonIcon from '@mui/icons-material/Person';
import SportsIcon from '@mui/icons-material/Sports';
import TimelineIcon from '@mui/icons-material/Timeline';
import StringHelper from "../helpers/string.helper";
import useDebounce from "../hooks/useDebounce";
import { CustomRouterLink } from "../components/common/CustomRouterLink";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "../store/reportsReducer";

// const FilterButton = styled<any>(Button)(({ theme, selected }: { theme: any, selected: boolean }) => ({
//     flexShrink: 0,
//     borderRadius: '100px',
//     borderColor: theme.palette.grey["400"],
//     backgroundColor: selected ? theme.palette.primary.dark : theme.palette.background.paper,
//     color: selected ? theme.palette.common.white : theme.palette.text.primary,
//     '&:hover': {
//         borderColor: theme.palette.grey["400"],
//         backgroundColor: selected ? theme.palette.primary.dark : theme.palette.background.paper,
//         color: selected ? theme.palette.common.white : theme.palette.text.primary,
//     },
// }));

const ReportTypeBadge = ({variant}: {variant: ReportType}) => {
    const theme = useTheme();
    const color1 = theme.palette.colors.bagde1;
    const bgColor1 = theme.palette.backgrounds.bagde1;
    const map: Record<ReportType, {color: string, bgColor: string, IconComponent: any }> = {
        'SCOUT_REPORT': {
            color: color1,
            bgColor: bgColor1,
            IconComponent: PersonIcon,
        },
        'MATCH_REPORT': {
            color: color1,
            bgColor: bgColor1,
            IconComponent: SportsIcon,
        },
        'SEASON_REPORT': {
            color: color1,
            bgColor: bgColor1,
            IconComponent: TimelineIcon,
        },
    };

    if (!map[variant]) return <></>;

    const { color, bgColor, IconComponent } = map[variant];

    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            color: color,
            padding: '2px 4px',
            borderRadius: '4px',
            backgroundColor: bgColor,
            fontSize: '8px',
        }}
    >
        <Typography
            sx={{
                fontSize: '8px',
                textWrap: 'nowrap'
            }}
        >
            {StringHelper.normalizeKey(variant)}
        </Typography>
       <IconComponent sx={{color, fontSize: '12px'}} />
    </Box>;
};

export default function ReportsPage() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    //const [filters, setFilters] = useState<Array<string>>(["Saved", "Scouting", "Season", "Match", "Opponent"]);
    const listContainerRef = useRef<any>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    //const [selectedFilter, setSelectedFilter] = useState<Set<string>>(new Set<string>().add(filters[0]));
    const page = useRef<number>(1);
    const limit = 30;
    const {
        items,
        loading,
        canLoadingMore
    } = useSelector((state: RootState) => state.reports);

    const onScroll = async () => {
        if (listContainerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listContainerRef.current;
          if (scrollTop + clientHeight >= scrollHeight - 1 && canLoadingMore && !loading && canLoadingMore) {
            page.current += 1;
            dispatch(fetchReports(await getAccessTokenSilently(), page.current, limit, debouncedSearchTerm));
          }
        }
    };

    useEffect(() => {
        const getReports = async () => {
            page.current = 1;
            dispatch(fetchReports(await getAccessTokenSilently(), page.current, limit, debouncedSearchTerm));
        };

        getReports();
    }, [getAccessTokenSilently, debouncedSearchTerm, /*selectedFilter,*/ dispatch]);

    return <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: 'background.default',
        }}
    >
        <Box
            sx={{
                width: "100%",
                maxWidth: "660px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "48px 16px 16px 16px"
            }}
        >
            <Box>
                <TextField
                    sx={{
                        width: "100%",
                    }}
                    size="small"
                    variant="outlined"
                    placeholder="Search reports"
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                />
            </Box>
            {/* <Box
                sx={{
                    overflow: "auto",
                    display: "flex",
                    gap: "16px",
                    pt: 2.5,
                    pb: 2.5,
                    flexShrink: 0,
                }}
            >
                {filters.map((filter, index) =>
                    <FilterButton
                        key={`filter-button-${index}`}
                        variant="outlined"
                        onClick={() => {
                            if (selectedFilter.has(filter) && selectedFilter.size > 1) {
                                selectedFilter.delete(filter);
                            } else {
                                new Set(selectedFilter.add(filter));
                            }
                            setSelectedFilter(new Set(selectedFilter));
                            setFilters([...filters]);
                        }}
                        selected={selectedFilter.has(filter)}
                    >
                        {filter}
                    </FilterButton>
                )}
            </Box> */}
            <Box
                ref={listContainerRef}
                onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: '8px',
                    overflow: 'auto',
                }}
            >
                {items.map((report, index) =>
                    <Box
                        component={CustomRouterLink}
                        to={{
                            pathname: `/report/${report.id}`,
                            state: { report }
                        }}
                        key={`report-item-${report.id}`}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '2px',
                            backgroundColor: "backgrounds.secondary",
                            borderRadius: '4px',
                            padding: '8px',
                            textDecoration: 'none'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '8px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'text.disabled',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap',
                                }}
                            >
                                {report.subname}
                            </Typography>
                            <ReportTypeBadge variant={report.report_type} />
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: 'text.primary',
                                    textDecoration: 'unset',
                                }}
                            >
                                {report.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: 'text.disabled',
                                }}
                            >
                                {report.subsubname}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress size="16px" />
                </Box>}
            </Box>
            {loading && items.length === 0 ? <PageLoader variant='full' /> : <></>}
        </Box>
    </Box>
}