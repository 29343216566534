export default class StringHelper {
    public static normalizeKey(key: string | undefined): string {
        if (key === undefined) return '';
        if (key.includes("_")) {
            const s = key.replaceAll("_", " ").toLocaleLowerCase();
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
        if (key.includes(",")) {
            return key.replaceAll(",", ", ");
        }
        const s = key.toLocaleLowerCase();
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    public static formatCurrency(value: number, currency: string = "EUR"): string {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
        }).format(value);
    }
}