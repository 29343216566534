import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ChatModel, MessageModel, api } from "../../api/twelve";
import InputArea from "./InputArea";
import { styled, Box, BoxProps, useTheme } from "@mui/material";
import PageLoader from "../elements/PageLoader";
import { MessageItem } from "./MessageItem";
import { useAuth0 } from '@auth0/auth0-react';
import { renameConversation } from "../../store/conversationsReducer";
import { config } from "../../config";
import { fetchSavedReports } from "../../store/savedReportsReducer";

type Props = {
  conversationId: number;
  chat: ChatModel;
  hasInput: boolean;
  onRedirect: (chatId: number) => void;
};

const MainChatBox = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '18px 0',
  maxWidth: '800px',
  flexDirection: 'column',
  gap: '18px',
}));

const ChatBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
}));

const MessagesBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  width: '100%',
  maxWidth: '800px',
  padding: '0 18px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

function isRenderable(m: MessageModel): boolean {
  const content = m.content;
  if (m.role === "user" && content!.length > 0) {
    return true;
  } else if (m.plots && m.role !== 'tool') {
    return true;
  } else if (m.role === "assistant" && content && content.length > 0) {
    return true;
  }
  return false;
}

export default function Chat(props: Props) {
  const theme = useTheme();
  const { conversationId, chat, hasInput, onRedirect } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [activePrompt, setActivePrompt] = useState("");
  const [pendingMessages, setPendingMessages] = useState<MessageModel[]>([]);
  const [redirectMessages, setRedirectMessages] = useState<MessageModel[]>([]);
  const [generating, setGenerating] = useState(false);
  const dispatch = useDispatch();
  const selectedConversationName = useSelector((state: RootState) => state.conversations.selectedConversation?.name);

  const processMessages = (messages: Array<MessageModel> | null) => {
    if (messages === null) return [];

    return Array.from(messages
      .filter(isRenderable)
      .sort((a, b) => Number(a.id) - Number(b.id))
      .reduce((map: Map<string, Array<MessageModel>>, m: MessageModel)=> {
        const group_key = m.group_id || `${m.id}`;
        if(!map.has(group_key)) map.set(group_key, []);
        map.set(group_key, [...map.get(group_key) || [], m]);
        return map;
      }, new Map<string, Array<MessageModel>>())
      .values())
      .map((messages): MessageModel => {
        const hasPlots = messages?.length > 1 && messages.every(m => m.plots);
        const message: MessageModel = {
          id: messages[0].id || 0,
          role: messages[0].role,
          content: hasPlots ? "" : messages[0].content,
          chatId: messages[0].chatId || 0,
          plots: hasPlots
          ? messages.map(m => ({
              url: m.plots[0]?.url,
              json_body: m.plots[0]?.json_body,
              description: m.content,
            }))
          : messages[0].plots,
          redirect: messages[0].redirect,
          disliked: messages[0].disliked,
          group_id: messages[0].group_id,
          report_public_id: messages[0].report_public_id,
          report_id: messages[0].report_id,
        };

        return message;
      });
  };

  const onSend = useCallback(
    (text: string) => {
      setPendingMessages([]);
      setActivePrompt(text);
      setGenerating(true);
      const fn = async () => {
        let hasRedirect:boolean | null = null;
        let hasSavedReport:boolean | null = null;
        const result = await api.addChatMessage(
          await getAccessTokenSilently(),
          conversationId,
          chat.id,
          { content: text },
          (chunks: MessageModel[][]) => {
            setPendingMessages(processMessages(chunks[0]));
            if (hasRedirect !== true) {
              hasRedirect = chunks.reduce((value, chunk) => value || chunk.some(a => a.tool_call_names?.some((b: string) => b === "redirect_to_scout")), false);
            }
            if (hasSavedReport !== true) {
              hasSavedReport = chunks.reduce((value, chunk) => value || chunk.some(a => a.report_public_id), false);
            }
          },
          // FIXME: We skip sinceId for now but it would be more efficient to only fetch the messages since the last message id (i.e. before the streamed messages)
          undefined 
        );
        //scrollToBottom();
        setPendingMessages([]);
        setRedirectMessages([]);
        setActivePrompt("");
        setMessages(processMessages(result));
        setGenerating(false);
        if (hasRedirect) {
          onRedirect(chat.id);
        }
        if (hasSavedReport) {
          dispatch(fetchSavedReports(await getAccessTokenSilently()));
        }
      };
      fn();
    },
    [chat.id, conversationId, onRedirect, getAccessTokenSilently, dispatch]
  );

  useEffect(() => {
    setLoading(true);
    const loadFn = async () => {
      const chatModel = await api.showChat(await getAccessTokenSilently(), conversationId, chat.id);
      setMessages(processMessages(chatModel.messages));
      setLoading(false);
    }
    const initOrLoadFn = async () => {
      const chatModel = await api.showChat(await getAccessTokenSilently(), conversationId, chat.id);
      if (!chatModel.messages?.length) {
        // no messages means chat has not been initialized
        setLoading(false);
        setGenerating(true);
        setActivePrompt("");
        const result = await api.addChatMessage(
          await getAccessTokenSilently(),
          conversationId,
          chat.id,
          { content: "" },
          (chunks: MessageModel[][]) => {
            setPendingMessages(chunks[0].filter(isRenderable));
          },
          undefined
        );
        setPendingMessages([]);
        setMessages(processMessages(result));
        setGenerating(false);
      } else {
        setMessages(processMessages(chatModel.messages));
        setLoading(false);
      }
    };
    // FIXME: Initializing the chat gives cause for a possible race condition. Sending two empty messages in quick succession will trigger this. The proper way of solving this is on the server to make it idempotent (i.e. refuse multiple initializations of one chat). Unfortunately that is a bit involved so we solve this in the frontend for now.
    if (api.setChatInitialized(chat.id)) {
      initOrLoadFn();
    } else {
      loadFn();
    }
  }, [chat.id, conversationId, getAccessTokenSilently]);

  const onUpdateDisliked = useCallback(
    async (messageId: number, disliked: boolean) => {
      await api.updateMessageDisliked(await getAccessTokenSilently(), conversationId, chat.id, messageId, disliked);
    },
    [chat.id, conversationId, getAccessTokenSilently])

  useEffect(() => {
    const renameConversationIfPossible = async () => {
      const userMessages = messages.filter(m => m.role === "user");
      if (selectedConversationName === config.conversationNaming.default && userMessages.length >= config.conversationNaming.userMessageThreshold) {
        dispatch(renameConversation(await getAccessTokenSilently(), conversationId));
      }
    }
    renameConversationIfPossible();
  }, [messages, conversationId, getAccessTokenSilently, dispatch, selectedConversationName]);

  const renderableMessages = messages.filter(isRenderable);
  return (
    <MainChatBox id={`chat-${chat.id}`}>
      <ChatBox>
        {loading ? (
          <PageLoader variant="inline" />
        ) : (
          // MESSAGE SECTION
          <MessagesBox>
            {renderableMessages.map((m, i) => (
              <MessageItem key={`message_${m.id}`} chatId={chat.id} message={m} permaToolbar={(i === renderableMessages.length - 1)} onUpdateDisliked={onUpdateDisliked} />
            ))}
            {activePrompt.length > 0 && <MessageItem key="prompt" chatId={chat.id} message={{ content: activePrompt, role: "user"} as MessageModel} permaToolbar={false} />}
            {pendingMessages.map((m) => (
              <MessageItem key={`pending_${m.index}`} chatId={chat.id} message={m} permaToolbar={false} />
            ))}
          </MessagesBox>
        )}
        {hasInput && <Box sx={{
          position: "absolute",
          left:0,
          width: '100%',
          bottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '18px',
          backgroundColor: theme.palette.background.default,
          }}
        >
          <InputArea onSend={onSend} loading={generating} disabled={!!loading} />
        </Box>}
      </ChatBox>

      { /* REDIRECT SECTION */}
      {!loading && redirectMessages.length > 0 && false && ( // Hide redirect messages from the user
        <ChatBox>
          <MessagesBox>
            {redirectMessages.filter(isRenderable).map((m) => (
              <MessageItem key={`redirect_${m.index}`} chatId={chat.id} message={m} permaToolbar={false} />
            ))}
          </MessagesBox>
        </ChatBox>
      )}
    </MainChatBox>
  );
}
